<template>
    <el-scrollbar>
        <div class="live-chat"
             @mouseenter="filterScroll(true)"
             @mouseleave="filterScroll(false)">
            <div class="live-chat-header">
                <h2 class="header-title fira">{{ headers.title }}</h2>
                <span class="description fira">{{ headers.teaser }}</span>
                <el-alert :title="widget.title"
                          :description="widget.teaser"
                          :style="{background:widget.colorBg}"
                          type="success"/>
            </div>
            <div :class="{active:showTyping}" class="typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <div class="chat-list">
                <li v-for="item in originalList"
                    :key="item"
                    class="chat-item">
                    <h2 class="title fira">{{ item.title }}</h2>
                    <div class="tag-inner">
                        <div class="tag fira" v-if="item.tag.title">{{ item.tag.title }}</div>
                        <div class="tag fira">{{ item.date }}</div>
                    </div>
                    <span class="description fira" v-html="item.content"></span>
                    <div class="image-list">
                        <div v-for="image in item.images" class="image-list-item">
                            <picture class="picture">
                                <source
                                    media="(max-width:767px)"
                                    :srcset="image.mobile"
                                />
                                <source
                                    media="(max-width:1023px)"
                                    :srcset="image.tablet"
                                />
                                <img
                                    class="list-image"
                                    :src="image.desktop"
                                />
                            </picture>

                            <div class="hover-inner">
                                <img src="../../../assets/icons/play-icon.png" class="icon" alt="">
                            </div>
                        </div>
                    </div>
                    <a :href="item.fileUrl" v-if="item.file"
                       class="download-container">
                        <svg width="12" height="18" viewBox="0 0 12 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="6.41797" y1="0.532227" x2="6.41797" y2="11.5322" stroke="#182236"
                                  stroke-linecap="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M11.6992 8.35059L6.94609 12.9159C6.65442 13.196 6.18152 13.196 5.88984 12.9159L1.13672 8.35059C0.84505 8.07044 0.84505 7.61623 1.13672 7.33609C1.4284 7.05594 1.9013 7.05594 2.19297 7.33609L6.41797 11.3941L10.643 7.33609C10.9346 7.05594 11.4075 7.05594 11.6992 7.33609C11.9909 7.61624 11.9909 8.07044 11.6992 8.35059Z"
                                  fill="#182236"/>
                            <line x1="11.418" y1="17.501" x2="1.41797" y2="17.501" stroke="#182236"
                                  stroke-linecap="round"/>
                        </svg>
                        <span class="title fira">{{ $fn.tr('Download Report') }}</span>
                    </a>
                </li>
            </div>
        </div>
    </el-scrollbar>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {
    ElAlert,
    ElScrollbar
} from 'element-plus'
import {useMenuStore} from "../../../store/pinia/menu";
import moment from "moment";

const props = defineProps({
    contentData: {
        type: Object,
    },
});

let store = useMenuStore()

// let originalList = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
let list = ref([])
let addInterval = ref(null)
let headers = computed(() => props.contentData?.conf?.header || {})
let widgetName = computed(() => props.contentData?.conf?.widgets)
let listIndex = ref(0)
let widget = computed(() => {
    return store.indx?.widgets?.[widgetName.value]?.[widgetName.value] || {}
})
let projectTags = computed(() => {
    return store.indx?.terms?.project_tags || []
})

let originalList = computed(() => props.contentData?.data?.list?.map(item => {
    return {
        ...item,
        date: store.getLang === 'ge' ? moment(item.date).locale("ka").format('DD MMM. YYYY')
            : store.getLang === 'ru' ? moment(item.date).locale("ru").format('DD MMM. YYYY') : moment(item.date).format('DD MMM. YYYY'),
        fileUrl: item.file?.[0]?.url,
        images: item.image?.map(item => ({
            desktop: item?.devices?.desktop,
            mobile: item?.devices?.mobile,
            tablet: item?.devices?.tablet,
        })),
        tag: projectTags.value?.filter(tag => {
            return tag.id === item.project_tags?.[0]
        })?.[0] || {}
    }
}))
let realList = computed(() => window.innerWidth < 1024 ? originalList.value : list.value)
const timeOutFunction = () => {
    if (list.value?.length === originalList.value?.length - 1) clearInterval(addInterval.value)

    if (originalList.value[listIndex.value]) list.value?.unshift(originalList.value[listIndex.value])
    listIndex.value++
}
let showTyping = ref(false)
const dotAnimation = () => {
    showTyping.value = true

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            showTyping.value = false
            resolve(true);
        }, 3000);
    });
}

const intervalAdd = () => {
    if (window.innerWidth < 1024) return
    addInterval.value = setInterval(async () => {
        dotAnimation().then(() => {
            timeOutFunction()
        })
    }, 9000)
}
const firstNews = () => {
    if (window.innerWidth < 1024) return

    dotAnimation().then(() => {
        timeOutFunction()
    })
}
const filterScroll = (value) => {
    if (window.innerWidth < 1024) return
    store.disableScrollStatus = value
}

onMounted(() => {
    // firstNews()
    // intervalAdd()
})
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.12, 0, .02, .98);
.el-scrollbar:deep {
    height: calc(100vh - 265px);
    overflow: unset;

    .el-scrollbar__bar {
        width: 4px;
        right: -1px;

        .el-scrollbar__thumb {
            background: #940128;
            opacity: 1;
        }
    }

    @media (max-width: 1023px) {
        height: auto;
        margin-top: 30px;
        .el-scrollbar__wrap {
            overflow: unset;
        }
    }
}

.live-chat {
    //height: calc(100vh - 265px);
    width: 430px;
    padding: 0 60px 0 40px;
    color: $primaryNavy;
    border-right: 2px solid rgba(191, 191, 191, 0.4);
    //overflow-y: auto;
    @media (max-width: 1023px) {
        width: 100%;
        padding: 0;
        border-right: none;
    }

    .live-chat-header:deep {
        .header-title {
            span {
                color: $burgundy;
                margin-right: 8px;
            }
        }

        .el-alert--success {
            margin-top: 21px;
            background: #D9A036;
            border-radius: 13px;
            padding: 13px 21px;

            .el-alert__title {
                color: white;
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            .el-alert__description {
                color: white;
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
            }

            .el-alert__close-btn {
                color: white;
                font-size: 18px;
            }
        }

        .description {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: block;
            margin-top: 13px;
        }
    }

    .typing {
        align-items: center;
        display: flex;
        margin: 0 auto;
        width: fit-content;
        height: 0;
        transition: all .6s .7s cubic-bezier(.23, -0.01, .02, .98);
        @media (max-width: 1023px) {
            display: none;
        }

        &.active {
            height: 100px;
            transition: all .6s cubic-bezier(.23, -0.01, .02, .98);

            .dot {
                opacity: 1 !important;
                transition: all .6s .7s cubic-bezier(.23, -0.01, .02, .98);
            }
        }

        .dot {
            animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
            background: #8C8C8C;
            border-radius: 50%;
            width: 8.82px;
            height: 8.82px;
            margin-right: 4px;
            vertical-align: middle;
            display: inline-block;
            opacity: 0;
            transition: all .45s cubic-bezier(.23, -0.01, .02, .98);

            &:nth-child(1) {
                animation-delay: 200ms;
            }

            &:nth-child(2) {
                animation-delay: 300ms;
            }

            &:nth-child(3) {
                animation-delay: 400ms;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @keyframes mercuryTypingAnimation {
            0% {
                transform: translateY(0px);
            }
            28% {
                transform: translateY(-7px);
            }
            44% {
                transform: translateY(0px);
            }
        }
    }

    .chat-list {
        margin-top: 40px;

        .chat-item {
            margin-top: 20px;
            padding: 20px;
            border-radius: 10px;
            border: 1px solid rgba(24, 34, 54, 0.2);
            //opacity: 0;
            //transform: translateY(50px);
            //transition: all .45s $ease;
            @media (max-width: 1023px) {
                opacity: 1 !important;
                transform: translateY(0) !important;
            }

            &:first-child {
                margin-top: 0;
            }

            &.active {
                opacity: 1;
                transform: translateY(0);
            }

            &:hover {
                .hover-inner {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            .title {
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
            }

            .tag-inner {
                margin-top: 13px;
                display: flex;
                align-items: center;

                .tag {
                    padding: 4px 12px;
                    font-weight: 400;
                    font-size: 13px;
                    text-transform: uppercase;
                    background: #d9a036;
                    margin-right: 8px;
                    border-radius: 3px;

                    &:last-child {
                        background: transparent;
                        border: 1px solid $burgundy;
                        margin-right: 0;
                    }
                }
            }

            .description {
                display: block;
                margin-top: 13px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
            }

            .image-list {
                margin-top: 13px;

                .image-list-item {
                    width: 55px;
                    height: 55px;
                    overflow: hidden;
                }

                .list-image {
                    width: 100%;
                    height: 100%;
                }

                .hover-inner {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(0deg, rgba(17, 25, 41, 0.5), rgba(17, 25, 41, 0.5));
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    pointer-events: none;
                    transition: all .45s ease;
                }
            }
        }
    }

    .download-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 13px;

        .title {
            margin-left: 11px;
            font-weight: 700;
            font-size: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $primaryNavy;
        }
    }

}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.7s cubic-bezier(.23, -0.01, .02, .98);
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .live-chat {
            color: white;
        }

        .download-container {
            line, path {
                fill: white;
                stroke: white;
            }

            .title {
                color: white !important;
            }
        }
    }
}

</style>
